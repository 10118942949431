import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import Input from "../components/Form/Input"
import { useAxiosClient } from "../service/useAxiosClient"
import { Fragment } from "react"
import Adviser from "../components/Adviser"
import TitlePage from "../components/TitlePage"
import parse from "react-html-parser"

export default function SearchCertified({ location }) {
  const [infoToSave, setInfoToSave] = useState({})
  const [options, setOptions] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [results, setResults] = useState(0)

  const [{ data: dataSelect }] = useAxiosClient({
    url: `/persons/web/seeker/certificates/`,
    method: "GET",
  })

  useEffect(() => {
    if (dataSelect && dataSelect.length) {
      setOptions(dataSelect)
    }
  }, [dataSelect])

  const renderOptions = () => {
    return (
      options &&
      options.map(item => {
        return (
          <option value={item.value} className="SearchCertified-italicSpan">
            {parse(item.name)}
          </option>
        )
      })
    )
  }

  const handleChangeInformation = ({ name, value }) => {
    setInfoToSave(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleSelect = ({ target }) => {
    setInfoToSave(prevState => {
      return {
        ...prevState,
        level: target.value,
      }
    })
  }

  const [, triggerSaveData] = useAxiosClient(
    {},
    {
      manual: true,
    }
  )

  const sendToAPi = async e => {
    e.preventDefault()
    triggerSaveData({
      url: `/persons/web/seeker/`,
      method: "post",
      data: {
        ...infoToSave,
      },
    })
      .then(({ data, status }) => {
        if (status !== 200 || !data) return
        setSearchResults(data.items)
        setResults(data.total_items)
      })
      .catch(() => {
        setSearchResults([])
        setResults(0)
      })
  }

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Busca tu asesor financiero certificado - Registro EFPA España" },
  ]

  const renderResults = () => {
    if (!searchResults.length) return <Fragment />

    return (
      <div className="SearchCertified-list">
        <span className="SearchCertified-list-title">
          <i className="fas fa-search"></i> Resultados de la búsqueda ({results}{" "}
          resultados)
        </span>
        <div className="SearchCertified-container">
          <div className="row efpa-row">{renderCards()}</div>
        </div>
      </div>
    )
  }

  const renderCards = () => {
    return (
      searchResults &&
      searchResults.map(item => {
        return (
          <div className="col-12 col-md-3 p-0">
            <Adviser
              name={item.full_name}
              // code={item.associate_code}
              description={item.description}
              state={item.state}
              // certification_name={item.certification_name}
              facebook={item.facebook_link}
              linkedin={item.linkedin_link}
              twitter={item.twitter_link}
            />
          </div>
        )
      })
    )
  }
  return (
    <Layout>
      <TitlePage
        title="Busca tu asesor financiero certificado"
        url={location.pathname}
      />
      <Hero
        image="/imgs/quienes-somos.jpg"
        text="Busca tu Asesor Financiero Certificado"
      />
      <div className="container950">
        <div className="SearchCertified">
          <Breadcrumb path={path} />
          <TitleBlock title="Registro EFPA España" icon="far fa-id-card" />
          <div className="SearchCertified-content">
            <form onSubmit={sendToAPi} className="SearchCertified-form">
              <Input
                className="SearchCertified-label"
                controlClassName="SearchCertified-input"
                label="Nombre"
                onChange={handleChangeInformation}
                name="name"
              />
              <Input
                className="SearchCertified-label"
                controlClassName="SearchCertified-input"
                label="Primer apellido"
                onChange={handleChangeInformation}
                name="last_name"
              />
              <Input
                className="SearchCertified-label"
                controlClassName="SearchCertified-input"
                label="Segundo apellido"
                onChange={handleChangeInformation}
                name="second_last_name"
              />
              <label htmlFor="search-certified-select" className="Form-label">
                Certificado:
              </label>
              {/* eslint-disable-next-line*/}
              <select
                id="search-certified-select"
                className="SearchCertified-select"
                onChange={handleSelect}
                name="level"
              >
                {renderOptions()}
              </select>
              <Input
                className="SearchCertified-label"
                controlClassName="SearchCertified-input"
                label="Codigo postal"
                onChange={handleChangeInformation}
                name="pc"
              />
              <Input
                className="SearchCertified-label"
                controlClassName="SearchCertified-input"
                label="Núm. asociado / Miembro registrado"
                onChange={handleChangeInformation}
                name="code"
              />
              <Input
                className="SearchCertified-label"
                controlClassName="SearchCertified-input"
                label="Lugar"
                onChange={handleChangeInformation}
                name="place"
              />
              <button type="submit" className="SearchCertified-btn">
                Buscar
              </button>
            </form>
            {renderResults()}
          </div>
        </div>
      </div>
    </Layout>
  )
}
